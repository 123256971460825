<template>
  <div class="container mb-5">
    <extra-img v-model="media"></extra-img>

    <a-button
      class="bg-success text-white ml-sm-1 ml-md-3 ml-lg-4"
      @click="addExtraImg"
      :disabled="!productId"
      >Rasm qo'shish</a-button
    >
  </div>
</template>

<script>
import ExtraImg from '../../components/Products/ExtraImgProduct.vue';
import rootUrl from '../../config/url';
import { mapActions } from 'vuex';
export default {
  components: {
    ExtraImg,
  },
  props: ['productId'],

  data() {
    return {
      media: {
        image: [],
      },
    };
  },

  methods: {
    ...mapActions({
      postExtraImg: 'product/postExtraImg',
    }),
    addExtraImg() {
      const TOKEN = sessionStorage.getItem('token');
      const TOKEN_PREFIX = 'Bearer';
      Promise.all(
        this.media.image.map((item) => {
          return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append('image', item);
            data.append('product', this.productId);
            fetch(`${rootUrl}/products/product-images/`, {
              method: 'POST',
              headers: {
                Authorization: TOKEN_PREFIX + ' ' + TOKEN,
              },
              body: data,
            })
              .then((res) => res.json())
              .then((data) => resolve(data))
              .catch((error) => reject(error));
          });
        })
      )
        .then(() => {
          this.$emit('re-add-img', true);
          this.$notification['success']({
            message: "Muffaqiyatli qo'shildi",
            description: "Mahsulotlarga rasmlar qo'shildi :)",
          });
        })
        .catch(() => {
          this.$notification['error']({
            message: 'Xatolik',
            description: "Serverga rasm qo'shilmadi ): birozdan keyin urining",
          });
        });
    },
  },
};
</script>

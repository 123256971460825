<template>
  <section class="conatiner">
    <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="Rasmlar">
        <a-row type="flex">
          <p v-if="images.length <= 0">Qo'shimcha rasmlar mavjud emas</p>
          <a-col v-for="img in images" :key="img.id" class="ml-3">
            <img :src="img.image" class="img-tag img-size" />
            <button @click="deleteImg(img.id)" class="delete-btn">
              <a-icon type="delete" />
            </button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    images: Array,
    default: () => [],
  },

  methods: {
    ...mapActions({
      deleteExtraImg: 'product/deleteExtraImg',
    }),
    deleteImg(id) {
      this.deleteExtraImg(id)
        .then(() => {
          this.$notification['success']({
            message: 'Success',
            description: "Qo'shimcha rasm o'chirildi",
          });
          this.$emit('deleteion', true);
        })
        .catch(() => {
          this.$notification['error']({
            message: 'Xatolik',
            description: "Birozdan so'ng urinib ko'ring",
          });
        });
    },
  },
};
</script>

<style scoped>
.img-tag {
  position: relative;
}

.img-size {
  width: 100px;
  height: 100px;
}
.delete-btn {
  position: absolute;
  top: -15px;
  right: -2px;
  border: none;
  border-radius: 10px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dc3545ff;
  color: white;
  text-align: center;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .img-size {
    width: 50px;
    height: 60px;
  }
  .delete-btn {
    width: 25px;
    height: 30px;
  }
}
</style>

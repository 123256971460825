<template>
  <section class="mt-4">
    <p v-if="error">
      Error yuzga keldi sal turib urinib koring):{{ error.message }}
    </p>
    <a-form :label-col="{ span: 2 }" v-else :wrapper-col="{ span: 8 }">
      <a-form-item label="Rasm">
        <input type="file" @change="imUpload" multiple />
      </a-form-item>
      <div class="row mb-3" v-if="imgsArray.length > 0">
        <p
          class="bg-danger p-2 text-white rounded ml-5"
          v-if="imgsArray.length > 5"
        >
          Maksimum 5 ta rasm kiriting
        </p>
        <div class="ml-md-3 ml-lg-5 d-flex">
          <div
            class="ml-2 position-relative"
            v-for="(img, index) in imgsArray"
            :key="index"
          >
            <img :src="img" class="img-size" />
            <button @click="deleteImg(index)" class="delete-btn">
              <a-icon type="delete" />
            </button>
          </div>
        </div>
      </div>
    </a-form>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      error: '',
      arrSubImg: [],
      serverSubImgFile: [],
    };
  },
  props: {
    value: {
      type: Object,
      deafult: () => ({}),
    },
  },
  computed: {
    imgsArray() {
      return this.arrSubImg && this.arrSubImg;
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  methods: {
    deleteImg(id) {
      this.arrSubImg.splice(id, 1);
      this.serverSubImgFile.splice(id, 1);
      this.value.image = this.serverSubImgFile;
    },
    ...mapActions({
      getAllProducts: 'product/getAllProducts',
    }),
    imUpload(e) {
      this.arrSubImg = [...e.target.files].map((item) =>
        URL.createObjectURL(item)
      );
      this.serverSubImgFile = [...e.target.files];
      this.value.image = this.serverSubImgFile;
      // const files = e.target.files[0];
      // this.value.image = files;
    },
  },
};
</script>

<style scoped>
.img-size {
  width: 100px;
  height: 100px;
}
.delete-btn {
  position: absolute;
  top: -15px;
  right: -2px;
  border: none;
  border-radius: 10px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dc3545ff;
  color: white;
  text-align: center;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .img-size {
    width: 43px;
    height: 51px;
  }
  .delete-btn {
    width: 25px;
    height: 30px;
  }
}

input[type='file'] {
  cursor: pointer;
  width: 148px;
  height: 50px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}
input[type='file']:focus {
  outline: none;
}

input[type='file']:before {
  width: 148px;
  font-size: 13px;
  line-height: 50px;
  content: 'Rasmlar Yuklash';
  display: inline-block;
  background: white;
  border: 1px solid whitesmoke;
  padding: 0 10px;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
}

input[type='file']::-webkit-file-upload-button {
  visibility: hidden;
}
</style>

<template>
  <div class="container">
    <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }">
      <a-form-item label="Ma'lumot">
        <a-input
          type="textarea"
          v-model="value.info"
          :value="value.info"
          rows="5"
        />
      </a-form-item>
      <a-form-item label="Ma'lumot [en] :">
        <a-input
          type="textarea"
          v-model="value.info_en"
          :value="value.info_en"
          rows="5"
        />
      </a-form-item>
      <a-form-item label="Ma'lumot [ru]">
        <a-input
          type="textarea"
          v-model="value.info_ru"
          :value="value.info_ru"
          rows="5"
        />
      </a-form-item>
      <a-form-item label="Ma'lumot [uz]">
        <a-input
          type="textarea"
          v-model="value.info_uz"
          :value="value.info_uz"
          rows="5"
        />
      </a-form-item>
      <a-form-item label="Diametr">
        <a-input v-model="value.diametr" :value="value.diametr" />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
};
</script>

<template>
  <div class="container mt-3">
    <h4 class="ml-sm-2 ml-md-4 ml-lg-5 font-weight-normal ">{{ title }}</h4>
    <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 8 }">
      <a-form-item label="Nomi">
        <a-input v-model="value.name" :value="value.name" />
      </a-form-item>
      <a-form-item label="Nomi [en]">
        <a-input v-model="value.name_en" :value="value.name_en" />
      </a-form-item>
      <a-form-item label="Nomi [ru]">
        <a-input v-model="value.name_ru" :value="value.name_ru" />
      </a-form-item>
      <a-form-item label="Nomi [uz]">
        <a-input v-model="value.name_uz" :value="value.name_uz" />
      </a-form-item>
      <a-form-item label="Narx">
        <a-input
          type="number"
          v-model="value.price"
          min="0"
          :value="value.price"
        />
      </a-form-item>
      <a-form-item label="Tartib raqam">
        <a-input
          type="number"
          v-model="value.order"
          min="0"
          :value="value.order"
        />
      </a-form-item>
      <a-form-item label="Rasm">
        <div class="custom">
          <div v-if="!value.id">
            <input type="file" @change="imgUpload" />
            <img
              :src="cPreviewImg"
              v-if="cPreviewImg"
              width="100"
              height="100"
              class="mt-2"
            />
          </div>

          <div v-else>
            <input type="file" @change="imgUpload" />
            <img
              :src="oldProductImg"
              :alt="oldImg"
              width="100"
              height="100"
              class="mt-2"
            />
          </div>
        </div>
      </a-form-item>
      <multiple-img
        v-if="value.images"
        :images="value.images"
        @deleteion="deleteSubImg"
      ></multiple-img>

      <a-form-item label="Kategoriya">
        <a-select
          show-search
          :value="secondValue"
          v-model="secondValue"
          placeholder="input search text"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearch"
          @change="handleChange"
        >
          <a-select-option
            v-for="cate in basicList"
            :key="cate.id"
            :value="cate.id"
          >
            {{ cate.name_ru }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MultipleImg from './MultipleImg.vue';
import debounce from 'lodash/debounce';
export default {
  components: {
    MultipleImg,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    title: String,
  },
  computed: {
    basicList() {
      return !this.subCategory
        ? this.value && [this.value.category]
        : this.subCategory;
    },
    cPreviewImg() {
      return this.previewImg && URL.createObjectURL(this.previewImg);
    },
    oldProductImg() {
      return this.oldImg
        ? this.oldImg
        : this.newPreviewImg && URL.createObjectURL(this.newPreviewImg);
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    this.handleSearch = debounce(this.handleSearch, 800);
    return {
      subCategory: [],
      oldImg: '',
      secondValue: '',
      parentCategory: [],
      previewImg: '',
      newPreviewImg: '',
    };
  },
  methods: {
    ...mapActions({
      getChildCategory: 'category/getChildCategory',
      loadAllCategory: 'category/loadAllCategory',
      getOneCategory: 'category/getOneCategory',
      filterCategory: 'category/filterCategory',
    }),
    handleChange(categoriyVal) {
      this.secondValue = categoriyVal;
      this.value.category = this.secondValue;
    },
    handleSearch(val) {
      this.filterCategory(val).then((res) => {
        this.subCategory = res.results;
      });
    },

    imgUpload(e) {
      this.oldImg = '';
      const files = e.target.files[0];
      this.previewImg = files;
      this.newPreviewImg = files;

      const newFile = {
        files,
        newData: true,
      };
      this.value.image = newFile;

      this.$notification['success']({
        message: "Rasm qo'shildi",

        description: "Rasm muffaqiyatli qo'shildi",
      });
    },
    deleteSubImg(bool) {
      this.$emit('deleteExtraImg', bool);
    },
  },

  created() {
    this.getChildCategory()
      .then(({ results }) => {
        this.subCategory = results;
        this.oldImg = this.value.image;
      })
      .catch((error) => {
        this.$notification['error']({
          message: 'Xatolik! Server bilan xatolik',
          description: error.message,
        });

        setTimeout(() => {
          this.$router.push('/product');
        }, 1000);
      });
  },
  updated() {
    if (this.value.category.name) {
      this.secondValue = this.value.category.name_ru;
    }
  },
};
</script>

<style scoped>
.custom input[type='file'] {
  cursor: pointer;
  width: 100%;
  height: 37px;
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
}
.custom input[type='file']:focus {
  outline: none;
}

.custom input[type='file']:before {
  width: 100%;
  font-size: 15px;
  line-height: 37px;

  content: 'Rasm Yuklash';
  display: inline-block;
  background: white;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
}

.custom input[type='file']::-webkit-file-upload-button {
  visibility: hidden;
}
</style>
